<script>
/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkCalendar from "@components/input/AkCalendar";
import AkInputText from "@components/input/AkInputText";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanCrisePeriodService from "@services/bilanCrisePeriodService";

export default {
  components: {AkFormView, AkInputText, AkCalendar},
  mixins: [randomRef, roleMixin],
  metaInfo() {
    return {
      title: "bilan_crise.period_details",
    }
  },
  data() {
    return {
      current: {}
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
  
    let p1 = bilanCrisePeriodService.findById(this.$route.params.id);
    p1.then(data => this.current = data);
  
    Promise.all([p1]).then(()=>{
      this.getRef().hideLoader();
    });
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan_crise.period_details')">
    <template v-slot:action>
      <RouterLink v-if="current.id !== undefined && this.canEditCrisis() && !current.year && this.canEditOrDeleteReview(current)"
                  :to="{ name: 'bilanCrisePeriodUpdate', params: {id: current.id}}"
                  class="btn btn-inverse-primary btn-xs"><i
        class="fe fe-edit pr-1"/>{{ $t('update') }}
      </RouterLink>
    </template>
    <template v-slot:form>
      <div class="form-row" v-if="current.createdById !== null">
        <AkInputText :disabled=true v-model=current.author class-name="col-md-4" :label="$t('bilan.author')"/>
      </div>
      <div class="form-row">
        <AkInputText v-model="current.name"
                     :disabled=true
                     :label="$t('bilan_crise.name')"
                     class-name="col-md-4"/>
      </div>
      <div class="form-row">
        <AkCalendar :disabled=true
                    v-model="current.dateStart"
                    :label="$t('bilan_crise.date_start')"
                    class-name="col-md-3"/>
        <AkCalendar :disabled=true
                    v-model="current.dateEnd"
                    :label="$t('bilan_crise.date_end')"
                    class-name="col-md-3"/>
      </div>
    </template>
  </AkFormView>
</template>